@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap");

* {
  font-family: "Manrope", sans-serif;
}

/* @import url("https://fonts.googleapis.com/css2?family=Frijole&display=swap");

* {
  font-family: "Frijole", cursive;
} */


/* @font-face {
  font-family: "Lausanne";
  src: url("./fonts/Lausanne-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Sometimes Times Italic";
  src: url("./fonts/Sometimes-Times-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Sometimes Times";
  src: url("./fonts/Sometimes-Times.ttf") format("truetype");
}

@font-face {
  font-family: "Danger";
  src: url("./fonts/Danger-Agenda.otf") format("opentype");
}
@font-face {
  font-family: "Capi";
  src: url("./fonts/capitellum-regular.ttf") format("opentype");
}
@font-face {
  font-family: "benz";
  src: url("./fonts/Corporate\ A\ Bold.otf") format("opentype");
}
@font-face {
  font-family: "brave";
  src: url("./fonts/bravehunter.ttf") format("opentype");
}
.lausanne {
  font-family: "Lausanne", sans-serif;
}

.sometimes-italic {
  font-family: "Sometimes Times Italic", Times, serif;
}

.sometimes {
  font-family: "Sometimes Times", Times, serif;
}

.danger {
  font-family: "Danger", sans-serif;
}
.capi {
  font-family: "capi", sans-serif;
}
.benz{
  font-family: "benz", sans-serif;
}
.brave {
  font-family: "brave", sans-serif;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
